/* ------------------------------------------------------------ *\
	Boxes
\* ------------------------------------------------------------ */

.boxes {
  .grid__col {
    + .grid__col {
      /*  Tablet Portait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        margin-top: 24px;
      }
    }
  }

  /*  Boxes Small  */

  &--small {
    max-width: initial;
    margin: 0 -1px 0 7px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      margin: 0;
    }

    .grid {
      margin: 0 -16px;

      /*  Small Desktop  */

      @media (max-width: $breakpoint-small-desktop) {
        margin: 0 -10px;
      }

      .grid__col {
        padding: 0 16px;
      }
    }
  }

  /*  Boxes Def  */

  &--def {
    max-width: 100%;
    margin: 0;
    position: relative;
    z-index: 10;

    .grid {
      margin: 0 -27px -11vw;

      /*  Larger Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        margin: 0 -15px -11vw;
      }

      .grid__col {
        padding: 0 27px 11vw;
        position: relative;

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          padding: 0 15px 11vw;
        }

        + .grid__col {
          /*  Tablet Portait  */

          @media (max-width: $breakpoint-tablet-portrait) {
            margin-top: 150px;
          }

          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            margin-top: 18vw;
          }
        }
      }
    }
  }

  /*  Boxes Size 1  */

  &--size1 {
    .grid {
      margin: 0 -16px;
      justify-content: flex-end;

      .grid__col {
        padding: 0 16px;
      }
    }

    .grid__col:nth-child(1) {
      .box {
        &:after {
          background-size: 100% 800px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 180px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 10px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 19vw;
            }
          }

          span {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 240px;
            }

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              max-width: 205px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 185px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              padding-right: 10px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 23vw;
            }
          }
        }
      }
    }

    .grid__col:nth-child(2) {
      .box {
        .box__features li:nth-child(1) {
          h6 {
            min-width: 220px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 9vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 10px;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 6vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 10px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 18vw;
            }
          }

          span {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 195px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 180px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              padding-right: 10px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 24vw;
            }
          }
        }
      }
    }
  }

  /*  Boxes Size 2  */

  &--size2 {
    .grid__col:nth-child(1) {
      .box {
        &:after {
          background-size: 100% 750px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 220px;

            /*  Small Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 9vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 7vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }

          span {
            max-width: 227px;

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 163px;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 30vw;
            }
          }

          span {
            max-width: 310px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 197px;
            }

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              max-width: 180px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 150px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 0;
              padding-right: 10px;
            }
          }

          span {
            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 197px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 140px;
            }
          }
        }
      }
    }

    .grid__col:nth-child(2) {
      .box {
        &:after {
          background-size: 100% 650px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 161px;

            /*  large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 10vw;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 180px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 12vw;
            }

            /*  large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 20vw;
            }
          }

          span {
            max-width: 260px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 168px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 150px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 203px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 8vw;
            }

            /*  Tablet Portrait  */

            @media (max-width: $breakpoint-tablet-portrait) {
              padding-right: 10px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }

          span {
            max-width: 260px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 210px;
            }

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              max-width: 184px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 140px;
            }
          }
        }
      }
    }

    .grid__col:nth-child(3) {
      .box {
        &:after {
          background-size: 100% 670px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 104px;
          }

          span {
            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 140px;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 150px;

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 6vw;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 131px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 5px;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }
        }
      }
    }
  }

  /*  Boxes Size3  */

  &--size3 {
    .grid__col:nth-child(4n + 1) {
      .box {
        &:after {
          background-size: 100% 680px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 162px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 9vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 10px;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 0;
            padding-right: 10px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 9vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 0;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              padding-right: 20px;
            }
          }

          span {
            max-width: 360px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 210px;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              max-width: 180px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 0;
            padding-right: 10px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }
        }
      }
    }

    .grid__col:nth-child(4n + 2) {
      .box {
        &:after {
          background-size: 100% 670px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 161px;

            /*  Small Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 10vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 20vw;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 200px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 12vw;
            }

            /*  large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }

          span {
            max-width: 280px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 180px;
            }

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              max-width: 160px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 180px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 9vw;
            }

            /*  Tablet Portrait  */

            @media (max-width: $breakpoint-tablet-portrait) {
              padding-right: 10px;
            }
          }

          span {
            max-width: 250px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              max-width: 210px;
            }

            /*  Large Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              max-width: 150px;
            }
          }
        }
      }
    }

    .grid__col:nth-child(4n + 3) {
      .box {
        &:after {
          background-size: 100% 790px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 162px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 12vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 9vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 35vw;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 240px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 12vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 25vw;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 206px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 0;
              padding-right: 5px;
            }
          }
        }
      }
    }

    .grid__col:nth-child(4n + 4) {
      .box {
        &:after {
          background-size: 100% 800px;
        }

        .box__features li:nth-child(1) {
          h6 {
            min-width: 161px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-large-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              min-width: 35vw;
            }
          }
        }

        .box__features li:nth-child(2) {
          h6 {
            min-width: 250px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 12vw;
            }

            /*  Small Desktop  */

            @media (max-width: $breakpoint-small-desktop) {
              min-width: 8vw;
            }

            /*  Mobile  */

            @media (max-width: $breakpoint-mobile) {
              padding-right: 20px;
            }
          }
        }

        .box__features li:nth-child(3) {
          h6 {
            min-width: 0px;
            padding-right: 10px;

            /*  Larger Desktop  */

            @media (max-width: $breakpoint-larger-desktop) {
              min-width: 7vw;
            }

            /*  Tablet Portrait  */

            @media (max-width: $breakpoint-tablet-portrait) {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	Box
\* ------------------------------------------------------------ */

.box {
  position: relative;
  background: #fff;
  border: 1px solid #707070;
  border-top: 0;
  padding: 52px 42px 34px;
  cursor: url(../../assets/images/arrow.png) 0 0, pointer;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    padding: 40px 32px 26px;
  }

  /*  Large Desktop  */

  @media (max-width: $breakpoint-large-desktop) {
    padding: 33px 26px 21px;
  }

  /*  Tablet Portait  */

  @media (max-width: $breakpoint-tablet-portrait) {
    padding: 50px 26px 21px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    padding: 26px 20px 16px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: url(../../assets/images/gradient.jpg) no-repeat center -590px;
    width: 100%;
    height: 30px;
    background-size: 100% 979px;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      height: 15px;
    }
  }

  .gradient {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 11;

    @media (max-width: $breakpoint-mobile) {
      height: 15px;
    }
  }

  h3 {
    text-transform: uppercase;
    margin: 0 0 27px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      font-size: 20px;
      margin: 0 0 7px;
    }
  }

  p {
    text-transform: uppercase;
    margin: 0;
    padding-left: 5px;
    min-height: 134px;
    min-height: 120px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      min-height: 103px;
    }

    /*  Tablet Portait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      min-height: 0;
      margin: 0 0 30px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      font-size: 21px;
      line-height: 1.07;
    }
  }

  .box__link {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    height: calc(100% - 30px);
    cursor: ne-resize;
    z-index: 10;
    cursor: url(../../assets/images/arrow.png) 0 0, pointer;
  }

  .box__features {
    list-style: none;
    font-family: 'WonderType', sans-serif;
    font-weight: 400;
    margin: 30px -7px 0 5px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      margin: 20px 0 0;
    }

    li {
      h6 {
        display: inline-block;
        margin: 0;
        vertical-align: middle;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          padding-right: 10px;
          /*display: block;  */
          /*margin: 0 0 5px; */
          /*min-width: 0 !important; */
        }
      }

      span {
        border-radius: 22px;
        display: inline-block;
        font-weight: 500;
        font-family: 'Azeret Mono', sans-serif;
        min-width: 181px;
        padding: 5px 19px;
        vertical-align: middle;
        position: relative;
        top: 1px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          min-width: 0;
          top: 0;
        }

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          padding: 3px 10px;
          min-width: 0 !important;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid #707070;
          border-radius: 22px;
        }
      }

      &:nth-child(2) {
        h6 {
          min-width: 146px;
        }
      }

      &:nth-child(3) {
        h6 {
          min-width: 206px;
        }

        span {
          min-width: 228px;
          max-width: calc(100% - 140px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          /*  Larger Desktop  */

          @media (max-width: $breakpoint-larger-desktop) {
            min-width: 0;
            max-width: calc(100% - 102px);
          }

          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            /*max-width: 100%; */
          }
        }
      }

      + li {
        margin: 4px 0 0;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          margin: 0;
        }
      }
    }
  }

  /*  Box Alt  */
  &--alt {
    &:after {
      background-position: center -190px;
    }

    .box__features {
      li {
        &:nth-child(1) {
          padding-right: 150px;

          span {
            min-width: 100px;
          }
        }

        &:nth-child(3) {
          h6 {
            min-width: 129px;
          }
        }
      }
    }
  }

  /*  Box Small  */

  &--small {
    padding: 63px 51px 34px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      padding: 48px 39px 26px;
    }

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      padding: 40px 26px 21px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 26px 20px 16px;
    }

    p {
      min-height: 100px;
      min-height: 90px;

      /*  Tablet Portait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        min-height: 0;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        padding: 0;
        margin: 0;
      }
    }

    .box__features {
      margin: 27px 0 0 10px;

      /*  Larger Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        margin: 20px 0 0;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin: 15px 0 0;
      }

      h6 {
        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 15px;
        }
      }

      span {
        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 15px;
          top: -2px;
          padding: 3px 13px;
        }
      }

      li {
        &:nth-child(3) {
          h6 {
            min-width: 140px;
          }
        }
      }
    }
  }

  /*  Box Def  */

  &--def {
    padding: 142px 39px 34px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      padding: 120px 30px 26px;
    }

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      padding: 100px 26px 26px;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      padding: 100px 15px 26px;
    }

    /*  Tablet Portait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      padding: 25vw 15px 26px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 20vw 20px 19px;
    }

    p {
      font-weight: 500;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        font-size: 15px;
        margin: 0;
      }
    }

    .box__image {
      position: absolute;
      left: 34px;
      right: 34px;
      bottom: 100%;
      padding-top: 38%;
      background-size: cover;
      transform: translateY(53%);
      z-index: 10;

      /*  Larger Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        left: 30px;
        right: 30px;
      }

      /*  Large Desktop  */

      @media (max-width: $breakpoint-large-desktop) {
        left: 26px;
        right: 26px;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        left: 20px;
        right: 20px;
      }
    }

    .box__features {
      margin: 7px 0 0 5px;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin: 15px 0 0;
      }

      li {
        &:nth-child(1) {
          display: block;

          h6 {
            min-width: 164px;
          }
        }

        &:nth-child(2) {
          h6 {
            min-width: 290px;
          }
        }

        &:nth-child(3) {
          h6 {
            min-width: 206px;
          }
        }
      }
    }
  }
}
