/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-5px);
	}
	100% {
		transform: translatey(0px);
	}
}

[class^='list-'] {
  list-style: none;
}

/* ------------------------------------------------------------ *\
	List Links
\* ------------------------------------------------------------ */

.list-links {
  text-transform: uppercase;
  font-family: 'WonderType', sans-serif;
  font-weight: 400;
  font-size: 60px;

  &--home {
    &.aos-animate {
      li:first-child {
        animation: float 8s ease-in-out infinite;
      }

      li {
        animation: float 10s ease-in-out infinite;
      }
    }  
  }



  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 46px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    font-size: 30px;
    border-top: 1px solid #000;
  }

  a {
    text-decoration: none;
    display: inline-block;
    position: relative;
    z-index: 5;

    &:hover {
      &:after {
        width: 0;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: #000;
      transition: width 0.4s;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  i {
    margin: 0 5px;
    position: relative;
    z-index: 5;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }

  li {
   
    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      border-bottom: 1px solid #000;
      padding: 19px 0;
    }

    + li {
      margin: 28px 0 0;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin: 0;
      }
    }

    &:nth-child(1) {
      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        text-align: right;
      }
    }

    &:nth-child(4) {
      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        text-align: right;
      }
    }
  }

  /*  List Links Alt  */
  &--home span, &--primary span {
    display: inline-block;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -4px;
      left: -22px;
      right: 0;
      bottom: -11px;
      background: url(../../assets/images/n1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 0;
      transition: opacity 0.4s;
      pointer-events: none;
      min-width: 567px;

      /*  Tablet Portrait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        min-width: 0;
        left: -10px;
        right: -10px;
        bottom: -4px;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
    
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }
  &--primary {
    font-size: 54px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      font-size: 46px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      font-size: 40px;
      margin-left: 10px;
      margin-bottom: 94px;
    }

    li {
      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        padding: 24px 7px 30px;
        position: relative;
      }

      + li {
        margin: 25px 0 0;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          /*margin: 10px 0 0; */
          margin: 0;
        }
      }

      &:nth-child(1) {
        i {
          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
          }
        }
      }

      &:nth-child(2) {
        i {
          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            position: absolute;
            top: 40px;
            right: 10px;
          }
        }
      }

      &:nth-child(3) {
        span {
          position: relative;
        }

        i {
          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            position: absolute;
            top: 50%;
            left: 100%;
            transform: translateY(-50%);
            margin-left: 10px;
          }
        }
      }

      &:nth-child(4) {
        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          text-align: right;
        }

        i {
          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
          }
        }
      }

      &:nth-child(5) {
        i {
          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            position: absolute;
            bottom: 40px;
            right: 10px;
          }
        }
      }
    }

    span {
      display: inline-block;
      position: relative;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        position: static;
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   top: -4px;
      //   left: -22px;
      //   right: 0;
      //   bottom: -11px;
      //   background: url(../../assets/images/n1.png) no-repeat 0 0;
      //   background-size: 100% 100%;
      //   opacity: 0;
      //   transition: opacity 0.4s;
      //   pointer-events: none;
      //   min-width: 567px;

      //   /*  Tablet Portrait  */

      //   @media (max-width: $breakpoint-tablet-portrait) {
      //     min-width: 0;
      //     left: -10px;
      //     right: -10px;
      //     bottom: -4px;
      //   }

      //   /*  Mobile  */

      //   @media (max-width: $breakpoint-mobile) {
      //     display: none;
      //   }
      // }
    }

    i {
      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: inline-block;
      }
    }

    .is-active {
      a {
        color: #fff;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          color: #000;
        }

        &:after {
          background: #fff;
        }
      }

      span {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

/* ------------------------------------------------------------ *\
	List links Alt
\* ------------------------------------------------------------ */

.list-links-alt {
  font-size: 40px;
  font-size: 35px;
  font-weight: 400;
  text-transform: uppercase;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 30px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    font-size: 27px;
  }

  a {
    text-decoration: none;
    display: inline-block;
    position: relative;
    z-index: 5;

    &:hover {
      &:after {
        width: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 0;
      height: 1px;
      background: #000;
      transition: width 0.4s;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }
    }
  }

  li {
    + li {
      margin: 9px 0 0;
      margin: 8px 0 0;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin-top: 30px;
      }
    }
  }

  .is-active {
    a {
      &:after {
        width: 100%;
      }
    }
  }
}
