/* ------------------------------------------------------------ *\
	Icons
\* ------------------------------------------------------------ */

[class^='ico-'] {
  display: inline-block;
  background-size: 100% 100%;
  vertical-align: middle;
}

.ico-dots1 {
  background-image: url(../../assets/images/ico-dots1.svg);
  width: 45px;
  height: 11px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 34px;
    height: 8px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 21px;
    height: 5px;
  }
}

.ico-dots2 {
  background-image: url(../../assets/images/ico-dots2.svg);
  width: 21px;
  height: 34px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 16px;
    height: 26px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 10px;
    height: 16px;
  }
}

.ico-dots3 {
  background-image: url(../../assets/images/ico-dots3.svg);
  width: 24px;
  height: 22px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 18px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 11px;
    height: 10px;
  }
}

.ico-dots4 {
  background-image: url(../../assets/images/ico-dots4.svg);
  width: 48px;
  height: 21px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 36px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 23px;
    height: 10px;
  }
}

.ico-dots5 {
  background-image: url(../../assets/images/ico-dots5.svg);
  width: 91px;
  height: 21px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 70px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 44px;
    height: 10px;
  }
}

.ico-dots6 {
  background-image: url(../../assets/images/ico-dots6.svg);
  width: 200px;
  height: 36px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 153px;
    height: 27px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 105px;
    height: 18px;
  }
}

.ico-dots11 {
  background-image: url(../../assets/images/ico-dots11.svg);
  width: 116px;
  height: 34px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 89px;
    height: 26px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 57px;
    height: 16px;
  }
}

.ico-dots12 {
  background-image: url(../../assets/images/ico-dots12.svg);
  width: 77px;
  height: 22px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 59px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 37px;
    height: 10px;
  }
}

.ico-dots13 {
  background-image: url(../../assets/images/ico-dots13.svg);
  width: 35px;
  height: 23px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 26px;
    height: 17px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 16px;
    height: 10px;
  }
}

.ico-dots14 {
  background-image: url(../../assets/images/ico-dots14.svg);
  width: 48px;
  height: 22px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 36px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 23px;
    height: 10px;
  }
}

.ico-dots15 {
  background-image: url(../../assets/images/ico-dots15.svg);
  width: 91px;
  height: 21px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 70px;
    height: 16px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: 44px;
    height: 10px;
  }
}

.ico-burger {
  position: relative;
  width: 40px;
  height: 22px;

  span {
    width: 100%;
    height: 6px;
    background: #000;
    position: absolute;
    border-radius: 3px;
    left: 0;
    transition: top 0.2s 0.25s, left 0.2s 0.25s, opacity 0.2s 0.25s,
      transform 0.2s 0s;

    &:first-child {
      top: 0;
    }

    &:nth-child(2) {
      top: 8px;
    }

    &:last-child {
      top: 16px;
    }
  }
}
