/* ------------------------------------------------------------ *\
	Popup Menu
\* ------------------------------------------------------------ */

.popup-menu {
  position: absolute;
  top: 128px;
  left: 0;
  width: 100%;
  max-height: calc(100vh - 128px);
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    top: 111px;
    max-height: calc(100vh - 111px);
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    top: 73px;
    max-height: calc(100vh - 73px);
    min-height: calc(100vh - 73px);
  }

  .popup__images {
    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }

    img {
      position: absolute;

      &:nth-child(1) {
        top: -19%;
        right: 16.5%;
        z-index: 5;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          opacity: 0;
        }
      }

      &:nth-child(2) {
        top: 8%;
        right: 4%;
        z-index: 4;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          opacity: 0;
        }
      }

      &:nth-child(3) {
        top: -40%;
        right: -9.5%;
      }

      &:nth-child(4) {
        top: 47.5%;
        right: 4%;
      }
    }
  }

  .popup__inner {
    padding: 205px 113px 64px;
    padding: 180px 113px 58px;
    position: relative;
    z-index: 10;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      padding-top: 20px;
      padding-left: 50px;
      padding-right: 50px;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      padding-left: 20px;
      padding-right: 20px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 47px 45px 100px 44px;
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      top: -64px;
      bottom: 78px;
      left: 0;
      width: 12px;
      height: 90%;
      background: url(../../assets/images/menu-left.jpg) repeat-y 0 0;
      display: none;

      /*  Mobile  */

      @media (max-width: $breakpoint-tablet-portrait) {
        display: block;
      }
    }

    > h2 {
      display: none;
      text-transform: uppercase;
      font-size: 33px;
      margin: 0 0 31px;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
  }

  .list-links {
    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -80px;
      right: 0;
      width: 28px;
      height: 35px;
      background: url(../../assets/images/arrow-bottom.png) repeat-y 0 0;
      display: none;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
  }

  .popup__bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: -35px;

    /*  Tablet Portrait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      margin-right: 0;
      display: block;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      background: #efefef;
      margin: 0 -45px;
      padding: 25px 20px;
      text-align: center;
      position: relative;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 214px;
      height: 20px;
      background: url(../../assets/images/menu-bottom.jpg) repeat-y 0 0;
      display: none;

      /*  Mobile  */

      @media (max-width: $breakpoint-tablet-portrait) {
        display: block;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 286px;
      height: 18px;
      background: url(../../assets/images/menu-colored.jpg) repeat-y 0 0;
      display: none;
      z-index: 5;

      /*  Mobile  */

      @media (max-width: $breakpoint-tablet-portrait) {
        display: block;
      }
    }

    ul {
      margin: 0;

      + ul {
        /*  Tablet Portrait  */

        @media (max-width: $breakpoint-tablet-portrait) {
          display: none;
        }
      }
    }
  }

  .footer {
    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      display: none;
    }
  }
}
