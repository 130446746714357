/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
  z-index: 3;
  position: relative;
  padding-bottom: 65px;

  /*  Mobile  */

  @media (max-width: $breakpoint-tablet-portrait) {
    padding: 107px 0 100px 26px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    padding: 107px 0 100px 6px;
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: none;

    /*  Mobile  */

    @media (max-width: $breakpoint-tablet-portrait) {
      display: block;
    }
  }

  &:after {
    background-image: url(../../assets/images/footer-m-top.jpg);
    height: 64px;
  }

  &:before {
    background-image: url(../../assets/images/footer-m-bottom.jpg);
    height: 78px;
    top: auto;
    bottom: 0;
  }

  > img {
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;

    /*  Tablet Portrait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      display: none;
    }
  }

  .logo {
    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      font-size: 54px;
    }
  }

  .footer__content {
    max-width: 19%;
    padding: 176px 0 0 9px;

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      padding-top: 50px;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      padding: 0;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      max-width: 100%;
    }
  }

  .footer__links {
    list-style: none;
    margin: 7px 0 0;
    text-transform: uppercase;
    font-weight: 700;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      margin: 28px 0 0;
      font-size: 20px;
    }

    a {
      text-decoration: none;
    }
  }

  .footer__content-alt {
    width: 32.4%;
    padding: 299px 0 0;
    font-size: 24px;
    line-height: 1.1;
    font-weight: 300;
    font-weight: 700;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      font-size: 18px;
    }

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      font-size: 14px;
      padding-top: 200px;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      padding: 0;
      position: relative;
      top: 100px;
    }

    /*  Tablet Portrait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      top: 0;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      width: 100%;
      margin: 26px 0 0;
      font-size: 17px;
    }

    br {
      /*  Tablet Portrait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        display: none;
      }
    }
  }

  .footer__inner {
    &:after {
      content: "";
      position: absolute;
      top: 64px;
      bottom: 78px;
      left: -6px;
      width: 33px;
      background-image: url(../../assets/images/footer-m-left.jpg);
      display: none;

      /*  Mobile  */

      @media (max-width: $breakpoint-tablet-portrait) {
        display: block;
      }
    }
    .shell {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: block;
      }
    }
  }
}
