/* ------------------------------------------------------------ *\
    Header
\* ------------------------------------------------------------ */

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 29px 0;
  z-index: 100;

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    padding: 18px 0;
  }

  .shell {
    display: flex;
    justify-content: space-between;
    align-items: center;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 0 17px;
    }
  }

  .logo {
    position: relative;
    left: -13px;

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      left: 0;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      top: 0;
      left: 7px;
    }
  }

  .header__link {
    font-family: 'Microgramma D', sans-serif;
    font-weight: 700;
    font-size: 54px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 0;
    border: none;
    background: none;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      font-size: 41px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      font-size: 0;
    }

    i {
      display: none;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: inline-block;
      }
    }
  }

  /*  Header Active  */

  &.is-active {
    + .popup-menu {
      opacity: 1;
      visibility: visible;
    }

    .ico-burger {
      span {
        transition: background 0.2s, top 0.2s, left 0.2s, opacity 0.2s,
          transform 0.2s 0.25s;

        &:first-child {
          top: 8px;
          transform: rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          top: 8px;
          transform: rotate(-45deg);
        }
      }
    }
  }
}
