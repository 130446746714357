/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widget-images-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  z-indeX: 0;

  img {
    position: absolute;
    width: 100%;
    top: 0;
  }
}

.widget-images {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    top: 185px;
  }

  img {
    position: absolute;

    &:nth-child(1) {
      top: 0;
      left: -2%;
    }

    &:nth-child(2) {
      top: 9%;
      left: 7%;
    }

    &:nth-child(3) {
      top: 1.6%;
      left: 34%;
    }

    &:nth-child(4) {
      top: 1.5%;
      left: 22.5%;
    }

    &:nth-child(5) {
      top: 5%;
      right: 2.5%;
    }

    &:nth-child(6) {
      top: 24.1%;
      left: 1.9%;
    }

    &:nth-child(7) {
      top: 54.9%;
      left: -0.8%;
      z-index: 2;
    }

    &:nth-child(8) {
      top: 57.7%;
      left: -7.5%;
    }

    &:nth-child(9) {
      top: 61.5%;
      right: -16%;
    }
  }

  /*  Widget Images Alt  */

  &--alt {
    img {
      &:nth-child(1) {
        top: 23%;
        left: auto;
        right: -27.4%;

        /*  Small Desktop  */

        @media (max-width: $breakpoint-small-desktop) {
          max-width: 400px;
        }

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          top: 9%;
          right: 50%;
          height: 600px;
          width: auto;
          max-width: 1200px;
        }
      }

      &:nth-child(2) {
        top: 51%;
        left: auto;
        right: -27.4%;

        /*  Small Desktop  */

        @media (max-width: $breakpoint-small-desktop) {
          max-width: 400px;
        }
      }

      &:nth-child(3) {
        top: auto;
        bottom: 15%;
        left: -47.4%;

        /*  Small Desktop  */

        @media (max-width: $breakpoint-small-desktop) {
          max-width: 800px;
        }
      }
    }
  }
}
