/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {
  display: inline-block;
  width: 255px;

  font-weight: 700;
  font-family: 'Microgramma D', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 54px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    width: 196px;
    font-size: 42px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    width: auto;
    font-size: 34px;
  }

  img {
    width: 100%;
    display: none;
  }
}
