/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell {
  padding: 0 50px;

  /*  Small Desktop  */

  @media (max-width: $breakpoint-small-desktop) {
    padding: 0 20px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    padding: 0 40px;
  }
}
