/* ------------------------------------------------------------ *\
	Settings
\* ------------------------------------------------------------ */

$font-family-sans-serif: 'Azeret Mono', sans-serif;

$font-size-base: 27px;
$line-height-base: 1.11;

$shell-max-width: 1150px;
$shell-gutter: 15px;

$breakpoint-larger-desktop: 1919px;
$breakpoint-large-desktop: 1439px;
$breakpoint-small-desktop: 1199px;
$breakpoint-tablet-portrait: 1023px;
$breakpoint-mobile: 767px;
$breakpoint-mobile-small: 374px;
