/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  padding-top: 128px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    padding-top: 111px;
  }

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    padding-top: 73px;
  }
}
