/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */

body {
  min-width: 320px;
  background: #fff;
  color: #000;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 20px;
  }

  @media (max-width: $breakpoint-large-desktop) {
    font-size: 16px;
  }
}

a {
  color: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  &[href^='tel'] {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: calc(1.2em / 2);
  font-weight: 400;
}

h1 {
  font-size: 164px;
  line-height: 1.3;
  font-family: 'Microgramma D', sans-serif;
  font-weight: 700;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 126px;
  }

  @media (max-width: $breakpoint-large-desktop) {
    font-size: 100px;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 50px;
  }

  @media (max-width: $breakpoint-mobile-small) {
    font-size: 45px;
  }
}

h2 {
  font-size: 106px;
  line-height: 1;
  font-family: 'Microgramma D', sans-serif;
  font-weight: 700;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 81px;
  }

  @media (max-width: $breakpoint-large-desktop) {
    font-size: 70px;
  }

  @media (max-width: $breakpoint-tablet-portrait) {
    font-size: 65px;
  }

  @media (max-width: $breakpoint-mobile) {
    font-size: 40px;
  }
}

h3 {
  font-size: 40px;
  line-height: 1.2;
  font-family: 'WonderType', sans-serif;
  font-weight: 400;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 30px;
  }
}

h6 {
  font-size: 27px;
  font-family: 'WonderType', sans-serif;
  font-weight: 400;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    font-size: 20px;
  }

  @media (max-width: $breakpoint-large-desktop) {
    font-size: 16px;
  }
}

p,
ul,
ol,
dl,
hr,
table,
blockquote {
  margin-bottom: 1.2em;
}

h1[class]:last-child,
h2[class]:last-child,
h3[class]:last-child,
h4[class]:last-child,
h5[class]:last-child,
h6[class]:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child {
  margin-bottom: 0;
}
