/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

.hidden {
  display: none !important;

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    display: none !important;
  }
}

.visible-mobile {
  display: none !important;

  /*  Mobile  */

  @media (max-width: $breakpoint-mobile) {
    display: block !important;
  }
}

/* ------------------------------------------------------------ *\
	Grid
\* ------------------------------------------------------------ */

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -31px;

  /*  Tablet Portrait  */

  @media (max-width: $breakpoint-tablet-portrait) {
    display: block;
    margin: 0 !important;
  }

  .grid__col {
    flex: 1;
    padding: 0 31px;

    /*  Tablet Portrait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      padding: 0 !important;
      max-width: 100% !important;
    }

    /*  Grid Col 1of3  */

    &--1of3 {
      flex: 0 0 33.33%;
      max-width: 33.33%;
    }

    /*  Grid Col 1of2  */

    &--1of2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    /*  Grid Col Size1  */

    &--size1 {
      flex: 0 0 51%;
      max-width: 51%;
    }

    /*  Grid Col Size2  */

    &--size2 {
      flex: 0 0 49%;
      max-width: 49%;
    }
  }
}

/* ------------------------------------------------------------ *\
	Load Animation
\* ------------------------------------------------------------ */

.load-animation {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: #fff;
  overflow: hidden;
  backface-visibility: hidden;
  transition: width 1.5s 1.7s;

  &:after {
    content: '// v01 loading...';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    text-align: center;
  }

  .load-animation__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: 5;
    backface-visibility: hidden;
    transition: width 1.5s 0.2s;
  }

  &.is-animated {
    width: 0;

    .load-animation__image {
      width: 100%;
    }
  }
}

/* ------------------------------------------------------------ *\
	AOS
\* ------------------------------------------------------------ */

.aos-init {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s, transform 0.6s;

  &.aos-animate {
    opacity: 1;
    transform: translateY(0);
  }
}
