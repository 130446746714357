/* ------------------------------------------------------------ *\
	Section 
\* ------------------------------------------------------------ */

.section {
  position: relative;
  z-index: 10;
  padding: 30px 31px 20px 80px;

  /*  Larger Desktop  */

  @media (max-width: $breakpoint-larger-desktop) {
    padding-left: 50px;
    padding-right: 50px;
  }

  /*  Small Desktop  */

  @media (max-width: $breakpoint-small-desktop) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section__head {
    margin: 0;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      margin-right: -50px;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      margin-right: 0;
    }

    h1 {
      text-transform: uppercase;
      display: inline-block;
      background: rgba(255, 255, 255, 0.9);
      line-height: 1;
      padding: 0 5px;
      margin: 0;
      backdrop-filter: grayscale(100%);

      span {
        font-family: "WonderType", sans-serif;
        font-weight: 400;
      }
    }

    h2 {
      text-transform: uppercase;
    }

    > strong {
      font-family: "WonderType", sans-serif;
      font-size: 54px;
      margin-left: 75px;
      margin-left: 20px;

      /*  Larger Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        font-size: 41px;
        /*margin: 0; */
        margin-left: 10px;
      }

      /*  Small Desktop  */

      @media (max-width: $breakpoint-small-desktop) {
        margin: 0;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        font-size: 20px;
      }
    }
  }

  .section__link {
    position: relative;
    display: inline-block;
    min-width: 735px;
    background: #efefef;
    text-decoration: underline;
    font-size: 40px;
    text-transform: uppercase;
    padding: 52px 20px 50px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      font-size: 30px;
      min-width: 565px;
      padding: 40px 15px 38px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      min-width: 0;
      width: 100%;
      padding: 51px 10px 65px 19px;
      font-size: 27px;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 25px;
      background: url(../../assets/images/img7.png) no-repeat 0 0;
      background-size: 100% 100%;

      /*  Larger Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        height: 20px;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        height: 25px;
      }
    }
  }

  .section__group {
    position: relative;

    > h3 {
      position: relative;
      text-transform: uppercase;
      padding-left: 40px;
      font-weight: 300;
      font-family: "Azeret Mono", sans-serif;
      margin: 0 0 149px;
      display: flex;
      align-items: flex-start;

      /*  Small Desktop  */

      @media (max-width: $breakpoint-small-desktop) {
        margin: 0 0 90px;
        display: block;
      }

      /*  Tablet Portrait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        margin: 0 0 150px;
        padding-left: 0;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        font-size: 33px;
        margin-bottom: 22vw;
        text-align: center;
      }

      span {
        font-family: "WonderType", sans-serif;
        font-weight: 400;
        display: block;
        margin-top: -4px;
        margin-left: auto;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 24px;
          margin-top: 14px;
        }
      }

      > i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-left: -67px;
        margin-top: -5px;

        /*  Tablet Portrait  */

        @media (max-width: $breakpoint-tablet-portrait) {
          margin: 0;
        }
      }
    }

    > i {
      display: block;
      margin: 85px auto 0;
      position: relative;
      left: -66px;

      /*  Tablet Portrait  */

      @media (max-width: $breakpoint-tablet-portrait) {
        left: 0;
      }

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin-top: 50px;
      }
    }

    + .section__group {
      margin: 193px 0 0;

      /*  Small Desktop  */

      @media (max-width: $breakpoint-small-desktop) {
        margin: 100px 0 0;
      }
    }
  }

  /*  Section Primary  */

  &--primary {
    padding: 32px 31px 111px 68px;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      padding: 32px 0 1vw;
    }

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      padding: 32px 0 111px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 20px 0 45px;
    }

    .section__head {
      margin: 0 0 55px;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        text-align: center;
        margin: 0 0 43px;
      }

      h2 {
        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          display: inline-block;
          text-align: left;
          font-size: 32px;
          /*padding: 0 83px; */
          max-width: 184px;
        }
      }
    }

    .section__body {
      padding-left: 7px;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        padding: 0;
      }
    }
  }

  /*  Section Hero  */
  &--hero {
    z-index: 11;
    padding: 40px 50px 0;
    right: 11.5%;

    /*  Large Desktop  */

    @media (max-width: $breakpoint-large-desktop) {
      padding: 45px 50px 0;
    }

    /*  Small Desktop  */

    @media (max-width: $breakpoint-small-desktop) {
      padding: 45px 20px 0;
    }

    /*  Tablet  */

    @media (max-width: $breakpoint-tablet-portrait) {
      right: 0;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 45px 42px 0;
    }

    .section__head {
      display: none;
      margin: 0 0 66px;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: block;
      }

      h1 {
        background: none;
        font-size: 32px;
      }

      > strong {
        display: block;
        text-align: right;
        font-size: 24px;
        margin: 3px 0 0;
      }
    }

    .box {
      font-size: 24px;

      /*  Large Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        font-size: inherit;
      }

      p {
        /*font-size: 24px; */
      }

      h6 {
        font-size: 24px;

        /*  Large Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          font-size: inherit;
        }
      }
    }
  }

  /*  Section Hero Alt  */

  &--hero-alt {
    padding-top: 0;
    /*  Mobile  */
    @media (max-width: $breakpoint-mobile) {
      padding: 23px 40px 0;
    }

    .section__head {
      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        display: none;
      }

      h1 {
        font-size: 148px;

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          font-size: 124px;
        }

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-large-desktop) {
          font-size: 90px;
        }

        /*  Tablet  */

        @media (max-width: $breakpoint-tablet-portrait) {
          font-size: 50px;
        }

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 45px;
        }
      }

      > strong {
        font-size: 49px;

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          font-size: 41px;
          /*margin: 0; */
          margin-left: 10px;
        }

        /*  Small Desktop  */

        @media (max-width: $breakpoint-small-desktop) {
          margin: 0;
        }

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 20px;
        }
      }
    }

    .box {
      font-size: 24px;

      /*  Large Desktop  */

      @media (max-width: $breakpoint-larger-desktop) {
        font-size: inherit;
      }

      p {
        /*font-size: 24px; */
      }

      h6 {
        font-size: 24px;

        /*  Large Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          font-size: inherit;
        }
      }
    }
  }

  /*  Section Small  */

  &--small {
    text-align: center;
    padding: 235px 71px 235px 0;

    /*  Larger Desktop  */

    @media (max-width: $breakpoint-larger-desktop) {
      padding: 235px 0;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 55px 0 30px;
    }
  }

  /*  Section Alt  */

  &--alt {
    padding: 30px 0 66px;

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 4px 0 50px;
    }

    .section__image {
      position: absolute;
      left: 51%;
      bottom: 0;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        bottom: auto;
        top: 1px;
        left: 100%;
        margin-left: -20px;
        height: 551px;
        width: auto;
        max-width: 1200px;
      }
    }

    .section__content {
      position: relative;
      z-index: 20;
      color: #fff;
      display: inline-block;

      /*  Mobile  */

      @media (max-width: $breakpoint-mobile) {
        margin: 0 -40px;
        width: calc(100% + 80px);
        padding: 37px 40px;
        text-align: center;
      }

      &:after {
        content: "";
        position: absolute;
        top: -12px;
        bottom: -18px;
        left: 2px;
        right: -41px;
        background: url(../../assets/images/img11.png) no-repeat 0 0;
        background-size: 100% 100%;

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }

      h1 {
        font-size: 97px;
        text-transform: uppercase;
        position: relative;
        z-index: 5;
        padding-left: 16px;

        /*  Larger Desktop  */

        @media (max-width: $breakpoint-larger-desktop) {
          font-size: 74px;
        }

        /*  Tablet Portrait  */

        @media (max-width: $breakpoint-tablet-portrait) {
          font-size: 61px;
        }

        /*  Mobile  */

        @media (max-width: $breakpoint-mobile) {
          font-size: 40px;
          padding: 0;
        }

        span {
          font-family: "WonderType", sans-serif;
          font-weight: 400;

          /*  Mobile  */

          @media (max-width: $breakpoint-mobile) {
            display: block;
            margin-bottom: -14px;
          }
        }
      }
    }
  }

  /*  Section Boxes  */

  &--boxes {
    padding: 4px 44px 120px 55px;

    /*  Tablet Portrait  */

    @media (max-width: $breakpoint-tablet-portrait) {
      padding: 4px 20px 120px;
    }

    /*  Mobile  */

    @media (max-width: $breakpoint-mobile) {
      padding: 4px 40px 80px;
    }
  }
}
